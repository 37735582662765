import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Button, ButtonGroup, Well } from '@yapay/design-system'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { generateBillet, BILLET_DID_GENERATE } from 'actions'
import OrderDetail from 'components/OrderDetail'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import imgBillet from 'static/img/billet.svg'
import ReactGA from 'react-ga'
import Recaptcha from 'components/Recaptcha'

export class BilletPayment extends Component {
  state = {
    tokenRecaptcha: '',
    dirtyPayButton: false,
  }

  pay = () => {
    this.setState({ dirtyPayButton: true })

    if (!this.state.tokenRecaptcha) {
      return
    }

    const { dispatch, history, payParams, redirectUrl } = this.props

    return dispatch(
      generateBillet({
        ...payParams,
        token_recaptcha: this.state.tokenRecaptcha,
      }),
    ).then(resp => {
      if (resp.type === BILLET_DID_GENERATE) {
        ReactGA.event({
          category: 'Billet Payment',
          action: 'Billet Generated',
        })

        return history.replace(`/pagamento/${resp.data.transaction_token}`)
      }

      ReactGA.event({
        category: 'Billet Payment',
        action: 'Billet Fail',
      })

      history.replace(`/boleto-falhou`, { redirectUrl })

      return null
    })
  }

  render() {
    const { isFetching, orderUndefined } = this.props

    if (orderUndefined) return <Redirect to="/nao-encontrado" />

    ReactGA.event({
      category: 'Choose Payment',
      action: 'Billet',
    })

    return (
      <div>
        <h2>Detalhes do pagamento</h2>

        <div className="flex items-center">
          <MediaQuery query="(min-width: 768px)">
            <figure className="ml-0">
              <img src={imgBillet} alt="Boleto" width="140" height="200" />
            </figure>
          </MediaQuery>

          <div>
            <h3 className="text-gray-600 flex items-center">
              <MediaQuery query="(max-width: 767px)">
                <Icon
                  icon="barcode"
                  width="35"
                  height="22"
                  className="fill-current text-gray-600 mr-4"
                />
              </MediaQuery>
              Boleto bancário
            </h3>

            <p>Vencimento: 2 dias</p>

            <p className="flex items-center">
              <strong className="pr-4 text-5xl">!</strong>
              <span>
                O prazo de compensação do boleto <br />é de até 2 dias úteis
                após o pagamento
              </span>
            </p>
          </div>
        </div>

        <MediaQuery query="(max-width: 767px)">
          <Well className="p-4 md:p-4 -mx-4" plain>
            <OrderDetail />
          </Well>
        </MediaQuery>

        <div className="mb-4">
          <Recaptcha
            onChange={tokenRecaptcha => {
              this.setState({ tokenRecaptcha })
            }}
            invalid={!this.state.tokenRecaptcha && this.state.dirtyPayButton}
            errorMessage="Confirme o reCAPTCHA para continuar"
          />
        </div>

        <ButtonGroup responsive className="items-center">
          <Link to="/escolha-pagamento" className="button text-center px-0">
            Voltar
          </Link>
          <Button
            className="sm:w-64"
            color="info"
            id="button-pay"
            onClick={this.pay}
            data-cy="generate-billet"
          >
            {isFetching ? 'Gerando...' : 'Gerar boleto'}
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

BilletPayment.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  orderUndefined: PropTypes.bool.isRequired,
  payParams: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      orderUndefined,
      order: { token, order_token, transaction_token, url_cancel, url_process },
    },
    user: { address_token, email },
    billet: { isFetching },
  } = state

  const redirectUrl = url_cancel || url_process || null

  const payParams = {
    token,
    seller_token: state.transaction.seller.token,
    address_token,
    payment_method: 'billet',
    order_token,
    transaction_token,
    payment_retry: state.transaction.paymentRetry,
    email,
  }

  if (
    process.env.REACT_APP_NEW_PAYMENT_FLOW_TOGGLE === 'true' &&
    state.transaction.type === 't'
  ) {
    payParams.customer = {
      name: state.user.name,
      company_name: state.user.company_name,
      email: state.user.email,
      cpf: state.user.cpf,
      cnpj: state.user.cnpj,
      zip_code: state.user.zip_code,
      street: state.user.street,
      number: state.user.number,
      neighborhood: state.user.neighborhood,
      city: state.user.city,
      state: state.user.state,
      phone: state.user.phone,
    }
  }

  return { payParams, redirectUrl, orderUndefined, isFetching }
}

export default withRouter(connect(mapStateToProps)(BilletPayment))
